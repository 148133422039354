<template>
    <div>
        <b-row v-if="state === 'initializing' || state === 'load'">
            <b-col class="text-center">
                <b-spinner large></b-spinner>
            </b-col>
        </b-row>
        <b-row v-if="state !== 'initializing' && state !== 'load'">
            <b-col cols="4">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Book time</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label>Date</label>
                            <b-form-datepicker v-model="dateSelected" @hidden="doLoadDate()"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label>Type of work</label>
                            <b-form-select v-model="workItem.workTypeId">
                                <b-form-select-option v-for="(item, index) in workTypes" :key="index" :value="item.workTypeId">{{item.description}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row v-if="workItem.workTypeId === 8">
                        <b-col>
                            <label>Ticket</label>
                            <b-input-group>
                                <b-form-input disabled v-model="workItem.ticketId"></b-form-input>
                                <b-input-group-append>
                                    <b-button size="sm" text="Button" variant="secondary" @click="openJobcardSearch">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button variant="no-back" class="link-btn mr-2" size="sm" @click="isBulkTime = true; workItem.from = null; workItem.to = null">Add Bulk Time</b-button>
                            <b-button variant="no-back" class="link-btn" size="sm" @click="isBulkTime = false; workItem.hours = 0">Add Specific Time</b-button>
                        </b-col>
                    </b-row>
                    <div v-if="isBulkTime">
                        <b-row>
                            <b-col>
                                <label>Hours Worked</label>
                                <b-form-input v-model="workItem.hours" type="number"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right">
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(0.25)">+0:15</b-button>
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(0.5)">+0:30</b-button>
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(1)">+1:00</b-button>
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(2)">+2:00</b-button>
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(4)">+4:00</b-button>
                                <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(8)">+8:00</b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="!isBulkTime">
                        <b-row>
                            <b-col>
                                <label>From</label>
                                <b-form-timepicker v-model="workItem.from" locale="en"></b-form-timepicker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label>To</label>
                                <b-form-timepicker v-model="workItem.to" locale="en" ></b-form-timepicker>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row>
                        <b-col>
                            <label>Comment</label>
                            <b-form-textarea v-model="workItem.description"></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col class="text-right">
                            <b-button variant="red" class="mr-2" @click="clearValues()">Clear</b-button>
                            <b-button variant="primary" @click="doAddTimeTo()">Add Time</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col>
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Bulk Time Booked</h4>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>
                                        
                                <!-- <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                       <b-button @click="openDeleteEntry(row.item)" size="sm" variant="red">Delete</b-button> 
                                    </b-row>
                                </template> -->

                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="mt-3">
                    <b-row>
                        <b-col>
                            <h4>Time Booked</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <vue-cal style="height: 500px" active-view="day" 
                                :disable-views="['years', 'year', 'week', 'month']"
                                hide-view-selector
                                hide-title-bar
                                :events="timeSheetData" :selected-date="dateSelectedString"
                                :on-event-click="itemClicked" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        
        <b-modal class="p-0" id="deleteEntry" size="md" hide-footer title="Delete entry" @close="closeDeleteEntry">
            <b-row>
                <b-col cols="12">
                    <label>Are you sure you wish to delete the entry?</label>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" squared class="ml-2" @click="deleteEntry">Delete</b-button>
                    <b-button variant="red" squared class="ml-2" @click="closeDeleteEntry">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal class="p-0" id="searchJobCard" size="xl" hide-footer title="Search Jobcard" @close="closeSearchJobcard">
            <b-row>
                <b-col>
                    <b-table striped hover :items="jobcardTableData.dataSource" :fields="jobcardTableData.tableColumns" 
                        :busy="jobcardTableData.isLoading" @row-clicked="addTicketToWorkItem" sort-icon-left>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>
                                
                        <template #cell(actions)="row">
                            <b-button @click="addTicketToWorkItem(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                            </b-button>
                        </template>

                    </b-table>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal class="p-0" id="editEntry" size="lg" hide-footer title="Edit entry" @close="closeEditEntry">
        <!-- {{selectedEntry}} -->
            <b-row>
                <b-col>
                    <label>Type of work</label>
                    <b-form-select v-model="selectedEntry.workTypeID">
                        <b-form-select-option v-for="(item, index) in workTypes" :key="index" :value="item.workTypeId">{{item.description}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <div v-if="selectedEntry.fromDateTime !== null && selectedEntry.toDateTime !== null">
                <b-row>
                    <b-col>
                        <label>From</label>
                        <b-form-timepicker v-model="selectedEntry.fromTime" locale="en"></b-form-timepicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>To</label>
                        <b-form-timepicker v-model="selectedEntry.toTime" locale="en"></b-form-timepicker>
                    </b-col>
                </b-row>
            </div>
            
            <div v-if="selectedEntry.fromDateTime === null && selectedEntry.toDateTime === null">
                <b-row>
                    <b-col>
                        <label>Hours Worked</label>
                        <b-form-input v-model="workItem.hours" type="number"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(0.25)">+0:15</b-button>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(0.5)">+0:30</b-button>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(1)">+1:00</b-button>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(2)">+2:00</b-button>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(4)">+4:00</b-button>
                        <b-button variant="link-btn" size="sm" @click="addTimeToBulkValue(8)">+8:00</b-button>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                        <b-col>
                            <label>Comment</label>
                            <b-form-textarea v-model="selectedEntry.description"></b-form-textarea>
                        </b-col>
                    </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col>
                    <!-- <b-button variant="red" squared class="ml-2" @click="openDeleteEntry(selectedEntry)">Delete</b-button> -->
                    <b-button variant="red" squared class="ml-2" @click="closeDeleteEntry">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { mapActions, mapMutations } from 'vuex'

export default {
    components: { VueCal },
    data: () => ({
        state: 'initializing',
        dateSelected: new Date(),
        dateSelectedString: new Date(),
        workTypes: [],
        isBulkTime: false,
        workItem: {
            ticketId: null,
            workTypeId: null,
            description: null,
            to: null,
            from: null,
            hours: 0,
            minutes: 0
        },
        timeSheetData: [],
        ogData:[],
        selectedEntry: {
            workTypeID: null
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Work Type',
                    key: 'workType',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Hours',
                    key: 'hours',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        
        jobcardTableData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Ticket Number',
                    key: 'referenceNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Client',
                    key: 'client',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Subject',
                    key: 'subject',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        jobcardSearch: {
            ticketNumber: null,
            client: null,
            subject: null
        }
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Timesheet'
            },
            {
                text: 'Manage',
                active: true
            }
        ])
        this.loadWorkTypes(1)
        .then((res) => {
            this.workTypes = res.data
            let a = this.dateSelected.toISOString()
            let aIndex = a.indexOf('T')
            this.dateSelected = a.substr(0, aIndex)
            this.dateSelectedString = new Date(this.dateSelected)
            this.doLoadDate()
        })
        .catch(() => {
            this.state = 'show'
        })
    },
    methods:{
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions(['loadWorkTypes', 'saveTimesheet', 'searchJobCards', 'loadDate', 'deleteTimesheetEntry']),
        ...mapActions('notificationService', [ 'addWarningMessage']),
        openJobcardSearch(){
            this.$bvModal.show('searchJobCard')
            this.doSearchJobCards()
        },
        addTicketToWorkItem(rowItem){
            this.workItem.ticketId = rowItem.ticketId
            this.closeSearchJobcard()
        },
        doSearchJobCards(){
            let request = []
            this.$store.commit('setJobcardSearchRequest', request)
            this.searchJobCards()
            .then((res) => {
                this.jobcardTableData.dataSource = res.data
                this.jobcardTableData.isLoading = false
            })
            .catch(() => {
                this.jobcardTableData.isLoading = false
            })
        },
        closeSearchJobcard(){
            this.$bvModal.hide('searchJobCard')
        },
        clearValues(){
            this.workItem = {
                workTypeId: null,
                description: null,
                to: null,
                from: null,
                hours: 0,
                minutes: 0
            }
        },
        closeDeleteEntry(){
            this.$bvModal.hide('deleteEntry')
            this.closeEditEntry()
        },
        closeEditEntry(){
            this.$bvModal.hide('editEntry')
        },
        openDeleteEntry(rowItem){
            this.selectedEntry = rowItem
            this.$bvModal.show('deleteEntry')
        },
        openViewEvent(rowItem){
            this.selectedEntry = rowItem
            this.selectedEntry.fromTime = rowItem.fromDateTime.substr(rowItem.fromDateTime.indexOf('T')+1)
            this.selectedEntry.toTime = (rowItem.toDateTime.substr(rowItem.fromDateTime.indexOf('T')+1))
            //console.log('adjskakjds', this.selectedEntry.toTime.substr(0, rowItem.toDateTime.search('.')))
            //console.log('adjskakjskdlaskdds', (this.selectedEntry.toTime.substr(0, rowItem.toDateTime.search('.'))).substr(rowItem.fromDateTime.indexOf('T')+1))
            this.$bvModal.show('editEntry')
        },
        deleteEntry(){
            let request = this.selectedEntry.timesheetId
            this.$store.commit('setDeleteTimesheetEntryRequest', request)
            this.deleteTimesheetEntry()
            .then(() => {
                let itemIndex = this.ogData.findIndex(element => element.timesheetId === this.selectedEntry.timesheetId)
                this.ogData.splice(itemIndex, 1)
                this.loadScreenInfo()
                this.closeDeleteEntry()
            })
            .catch(() => {
                this.closeDeleteEntry()
            })
        },
        itemClicked(event){
            let itemIndex = this.ogData.findIndex(element => element.timesheetId === event.timesheetId)
            this.openViewEvent(this.ogData[itemIndex])
        },
        addTimeToBulkValue(timeAdded){
            this.workItem.hours += timeAdded
        },
        loadScreenInfo(){
            this.state = 'load'
            let bulkTime = []
            let definedTime = []

            for (let i = 0; i < this.ogData.length; i++) {
                const element = this.ogData[i];
                if (element.fromDateTime === null && element.toDateTime === null) {
                    bulkTime.push(element)
                } else {
                    if (element.fromDateTime !== null && element.toDateTime !== null) {
                        let from = element.fromDateTime.replace("T", ' ')
                        let to = element.toDateTime.replace("T", ' ')
                        let timedDate = {
                            start: from,
                            end: to,
                            title: element.description,
                            class: 'calendar-view',
                            timesheetId: element.timesheetId
                        }
                        definedTime.push(timedDate)
                    }
                }
            }

            //console.log('bulk time', bulkTime)
            this.tableData.dataSource = bulkTime
            this.tableData.isLoading = false

            //console.log('defined time', definedTime)
            this.timeSheetData = definedTime
            this.state = 'show'
        },
        doLoadDate(){
            this.state = 'load'
            this.tableData.isLoading = true
            this.$store.commit('setLoadDateRequest', this.dateSelected)
            this.loadDate()
            .then((res) => {
                this.ogData = res.data
                this.dateSelectedString = new Date(this.dateSelected)
                this.loadScreenInfo()
            })
            .catch(() => {
                this.state = 'show'
            })
        },
        doAddTimeTo(){
            if (this.workItem.workTypeId === null) {
                this.addWarningMessage('Please enter a work type to continue')
                return
            }
            let request = {
                date: this.dateSelected,
                workTypeID: this.workItem.workTypeId,
                description: this.workItem.description,
                hours: 0,
                fromDateTime: null,
                toDateTime: null,
                ticketReferenceNo: this.workItem.ticketId
            }

            if (!this.isBulkTime) {
                let a = new Date(this.dateSelected)
                let b = new Date(this.dateSelected)

                a.setHours(this.workItem.to.substr(0,2))
                a.setMinutes(this.workItem.to.substr(3,2))
                a.setHours(a.getHours()+2)
                request.toDateTime = a

                b.setHours(this.workItem.from.substr(0,2))
                b.setMinutes(this.workItem.from.substr(3,2))
                b.setHours(b.getHours()+2)
                request.fromDateTime = b
            } else {
                //add something that calculates the minutes and then adds it
                request.hours = this.workItem.hours
            }

            this.$store.commit('setSaveTimesheetRequest', request)
            this.saveTimesheet()
            .then(() => {
                this.doLoadDate()
                this.clearValues()
            })
        }
    }
}
</script>